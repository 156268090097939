import React, { useState } from 'react';
import '../assets/style/DownloadAppSection.css';
import hrImage from '../assets/images/hr.png';
import mockup2 from '../assets/images/mockup2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';




const DownloadAppSection = () => {


  return (
    <section className="download-app-section ">
         
        <img src={mockup2} alt="عرض التطبيق" />
      
    <div className="download-app-info">
      <h2>تحميل التطبيق</h2>
      <img src={hrImage} alt="hr img" className="download-hr-image" />
     
      <div className="download-buttons">
        <a href="https://play.google.com/store/apps" target="_blank" rel="noopener noreferrer" className="download-button">
          
          <span className='me-3'>تحميل التطبيق من خلال <br /> <span className='downloadPlatform'> جوجل بلاي </span ></span>
          <FontAwesomeIcon icon={faGooglePlay} size="2x" />
        </a>
        <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer" className="download-button">
          
          <span className='me-3'>تحميل التطبيق من خلال <br /> <span className='downloadPlatform'> أبل ستور</span></span>
          <FontAwesomeIcon icon={faApple} size="2x" />
        </a>
      </div>
    </div>
  </section>

  );
};

export default DownloadAppSection;
