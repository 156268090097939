import React, { useEffect, useRef } from 'react';
import '../assets/style/home.css'
import hrImage from '../assets/images/hr.png';
import FaqSection from '../components/FaqSection';
import DownloadAppSection from '../components/DownloadAppSection';
import ContactSection from '../components/ContactSection';
import mockup3 from '../assets/images/mockup3.png'
import mockup1 from '../assets/images/mockup1.png'
import mockup5 from '../assets/images/mockup5.png'
function Home() {

  const featureItemsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target); // Stop observing the element after animating
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    featureItemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);
      }
    });

    return () => {
      // Clean up the observer when the component is unmounted
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <header className="app-header">
      <div className="app-info">
        <h1> يلق </h1>
        <p> مع يلق، اغسل سيارتك ونظف منزلك بسهولة وجودة عالية في المكان والوقت المناسبين لك. </p>
        <button className="install-button">ثبت التطبيق الآن</button>
      </div>
      <div className="app-mockup">
        <img src={mockup3} alt="عرض التطبيق" className='img3' />
        <img src={mockup1} alt="عرض التطبيق" className='img1' />
      </div>
    </header>
    <section className="features-section">
        <div className='centered-content'>
            <p>تطبيق يلق</p>
            <img src={hrImage} alt="hr img" />
            <p className='w-75'> مع يلق، يمكنك غسل سيارتك وتوفير الوقود، الطاقة، والوقت الذي تستهلكه في المغاسل التقليدية. نحن الخيار الأفضل لعملاء غسيل السيارات في المملكة العربية السعودية، حيث نوفر لك تجربة استثنائية وسهلة مع خدمات غسيل سيارات عالية الجودة. بالإضافة إلى ذلك، نقدم خدمات منزلية تلبي احتياجاتك اليومية، مثل تنظيف معين أو شامل وتنظيف ما بعد التشطيب، وكل ذلك يتم في الوقت والمكان المناسبين لك.</p>
        </div>
      <img
        src={mockup5}
        alt="Phone Mockup"
        className="features-image"
      />
      <div className="features-container">
        <div className="feature-item item-top-left " ref={(el) => featureItemsRef.current[0] = el}>
          <div className="circle"></div>
          <h2>رؤيتنا</h2>
          <p> نسعى في "يلق" إلى أن نكون الرواد في تقديم خدمات غسيل السيارات وخدمات المنازل بطريقة مبتكرة وسهلة تلبي احتياجات عملائنا وتفوق توقعاتهم. نطمح أن نصبح الخيار الأول لكل مالك سيارة وكل منزل يبحث عن جودة الخدمة والراحة، عبر تقديم تجربة غسيل سيارات وتنظيف منازل متكاملة ومدعومة بأحدث التقنيات وأفضل الممارسات.</p>
        </div>
        <div className="feature-item item-top-right " ref={(el) => featureItemsRef.current[1] = el}>
          <div className="circle"></div>
          <h2>هدفنا</h2>
          <p> هدفنا هو تقديم خدمات غسيل سيارات وخدمات منازل تليق بعملائنا الكرام، حيث نضع رضا العميل في المقام الأول من خلال تقديم خدمات متميزة وفعالة. نعمل جاهدين لتطوير خدماتنا بشكل مستمر، مع التركيز على تحقيق أعلى مستويات الجودة والاهتمام بالتفاصيل التي تحدث الفارق سواء في سيارتك أو في منزلك. </p>
        </div>
        <div className="feature-item item-bottom-left " ref={(el) => featureItemsRef.current[2] = el}>
          <div className="circle"></div>
          <h2>رسالتنا</h2>
          <p> في "يلق"، نلتزم بتقديم خدمات غسيل سيارات وخدمات منازل ذات جودة استثنائية تجعل من عملية غسيل السيارة وتنظيف المنزل تجربة سهلة ومريحة. نحن هنا لنحقق راحة عملائنا عبر تقديم خدمات عالية الجودة بأسلوب بسيط وفعال، يضمن توفير الوقت والجهد سواء كنت بحاجة إلى خدمة لسيارتك أو لمنزلك. </p>
        </div>
        <div className="feature-item item-bottom-right " ref={(el) => featureItemsRef.current[3] = el}>
          <div className="circle"></div>
          <h2>الجودة أولاً</h2>
          <p> في "يلق"، نؤمن بأن الجودة هي أساس كل شيء. لذلك، نحن ملتزمون بتقديم أعلى معايير الجودة في جميع خدماتنا، مع التركيز على استخدام أفضل المواد والممارسات لضمان نتائج تفوق توقعات عملائنا. نحن نعتبر الجودة أولويتنا الأولى، ونسعى لتحقيقها في كل جانب من جوانب خدماتنا، سواء كانت لخدمة سيارتك أو منزلك. </p>
        </div>
      </div>
    </section>
    <FaqSection />
    <DownloadAppSection />
    <ContactSection />
    </div>
  );
}

export default Home;
