import React, { useState } from 'react';
import '../assets/style/FaqSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import mockup4 from '../assets/images/mockup4.png'

const faqs = [
  { question: "ما هي الخدمات التي يقدمها يلق؟", answer: "يلق يقدم خدمات غسيل السيارات عند الطلب وخدمات تنظيف المنزل المتنوعة. تشمل خدمات تنظيف المنزل التنظيف العام، وتنظيف الحمام والمطبخ، والتنظيف بعد التشطيب" },
  { question: "كيف يمكنني حجز خدمة عبر يلق؟", answer: "حجز خدمة عبر يلق سهل للغاية. قم بتنزيل التطبيق، اختر الخدمة التي تحتاجها (غسيل السيارة أو تنظيف المنزل)، حدد الوقت والموقع المفضلين لديك، ثم أكد الحجز" },
  { question: "هل يمكنني تحديد موعد الخدمة لوقت لاحق؟", answer: "نعم، يمكنك تحديد موعد الخدمة لوقت لاحق يناسبك. ما عليك سوى اختيار التاريخ والوقت المطلوبين عند إجراء الحجز" },
  { question: "ما هي المناطق التي تغطيها خدمات يلق؟", answer: "يغطى يلق حاليًا عدة مواقع في جميع أنحاء المملكة العربية السعودية. تحقق من التطبيق لمعرفة ما إذا كان موقعك مشمولاً" },
  { question: "كيف يمكنني دفع ثمن الخدمات؟", answer: "يمكنك الدفع ب ابل باي من خلال تطبيق يلق باستخدام طرق متنوعة، بما في ذلك بطاقات الائتمان والمحافظ الرقمية" },
  { question: "هل يمكنني إدارة عدة مواقع في التطبيق؟", answer: "نعم، يتيح لك يلق حفظ وإدارة مواقع متعددة داخل التطبيق لتسهيل الحجز في المستقبل" },
  { question: "كيف أحجز خدمة تنظيف منزلي؟", answer: `.الخطوة 1: ابدأ بتحديد قسم "خدمات المنازل" في التطبيق
.الخطوة 2: اختر خدمة التنظيف المفضلة لديك من الخيارات المتاحة
.الخطوة 3: حدد موعدًا للفحص باختيار الوقت والمكان المناسبين
.الخطوة 4: أكد الحجز وأكمل الدفع بأمان من خلال التطبيق
.الخطوة 5: تتبع حالة الحجز في قسم 'الحجوزات` },
  { question: "هل يمكنني تتبع حجوزات خدمات المنزل الخاصة بي؟", answer: "نعم، بعد تأكيد الحجز، يمكنك بسهولة تتبع حالة طلباتك في قسم 'الحجوزات' داخل التطبيق. يساعدك ذلك على متابعة مواعيد الفحص وتأكيدات الحجز وأي تغييرات متعلقة بخدمتك" },
  { question: "هل يمكنني إلغاء أو تعديل الحجز بعد تأكيده؟", answer: "نعم، يمكنك تعديل أو إلغاء حجزك حتى ساعة واحدة قبل موعد الخدمة المحدد بدون أي رسوم إضافية" },
  { question: "هل أحتاج إلى التواجد في المنزل أثناء تقديم خدمة المنازل؟", answer: "يفضل أن تكون موجودًا أثناء تقديم الخدمة، لكن يمكنك التنسيق مع فريق العمل في حالة عدم قدرتك على التواجد" },
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="custom-faq-section">
      <div className="custom-faq-image">
        <img src={mockup4} alt="Mockup Image" />
      </div>
      {/* Top Wave */}
      <div className="custom-wave-top">
        <svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#f0f0f0"
            fillOpacity="1"
            d="M0,224L30,213.3C60,203,120,181,180,160C240,139,300,117,360,112C420,107,480,117,540,133.3C600,149,660,171,720,192C780,213,840,235,900,224C960,213,1020,171,1080,170.7C1140,171,1200,213,1260,213.3C1320,213,1380,171,1410,149.3L1440,128L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
          ></path>
        </svg>
      </div>

      {/* FAQ Content */}
      <div className="custom-faq-content mt-5">
        <h2>الأسئلة الشائعة</h2>
        <div className="custom-underline"></div>
        <div className="custom-accordion">
          {faqs.map((faq, index) => (
            <div className="custom-accordion-item" key={index}>
              <button
                className={`custom-accordion-button ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                <FontAwesomeIcon
                  icon={activeIndex === index ? faMinus : faPlus}
                  className="custom-accordion-icon"
                />
                <span className="fw-bolder">{faq.question}</span>
              </button>
              <div
                className="custom-accordion-content"
                style={{ display: activeIndex === index ? 'block' : 'none' }}
              >
                {faq.answer.split('\n').map((step, i) => (
                  <p key={i}>{step}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
