import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../assets/style/ContactSection.css';
import { Link } from 'react-router-dom';

const ContactSection = () => {
  return (
    <section className="contact-section">
      <div className="contact-content">
        <h2>تواصل معنا</h2>
        <div className="contact-info">
          {/* <p><strong>الموقع:</strong> شارع 123، مدينة، دولة</p> */}
          <p style={{direction:'rtl'}}><strong>البريد الالكتروني:</strong> info@yeleg.online</p>
          <p><strong>التليفون:</strong> 0535666441</p>
          
        </div>
        <div className="social-media-icons">
         
          <a href="https://x.com/YelegApp" target="_blank" rel="noopener noreferrer" aria-label="X">
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a href="https://www.instagram.com/yelegapp/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
         
        </div>
       
      <div className='mb-2'>
      <Link to="/terms-and-conditions" className="terms-link">
        الشروط والأحكام
      </Link>
      <Link to="/privacy-policy" className="terms-link ms-2">
        سياسة الخصوصية
      </Link>
      </div>
      {/* <Link to="/privacy-policy" className="text-white link-underline link-underline-opacity-0 mt-5">
        سياسة الخصوصية
      </Link> */}
      </div>
    </section>
  );
};

export default ContactSection;
